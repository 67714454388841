<template>
    <div class="vehicleOffer">
        <div class="vehicleInformation">
            <h3>{{ offer.vehicleInformation.modelName }}</h3>
            <v-layout row class="flex-row" v-if="offer.vehicleInformation.transmissionType">
                <v-flex md8 sm10 xs12 wrap>
                    <p v-if="offer.vehicleInformation.hybridCode != 'BEVE'">{{ offer.vehicleInformation.transmissionName
                        || 'Manual' }}</p>
                    <p v-if="offer.vehicleInformation.hybridCode = 'BEVE'">{{ offer.vehicleInformation.transmissionName
                        || 'Electric' }}</p>
                </v-flex>
            </v-layout>
        </div>
        <div class="offerDetails">
            <mq-layout mq="sm+">
                <table class="financeParameters" v-if="offer.financeParameters">
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>                
                    <tr>
                        <td>
                            <p class="productName">{{
                                offer.financeProductDescription.productName }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="financeParametersHeadline">
                            <p class="headline">{{ labels.engineSelectionPage.calculationInDetail }}</p>
                        </td>
                        <td>
                            <v-layout row class="parameterRow">
                                <v-flex desktop8 laptop8 tablet8 wrap v-if="parameters.province">
                                    <p class="parameterName">{{ labels.mainPage.mainPageProvinceLabel }}</p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap v-if="parameters.province">
                                    <p class="parameterValue">{{ parameters.province }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.TotalCashPrice">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.TotalCashPrice.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.TotalCashPrice.parameterDescription.parameterName">
                                        {{ offer.financeParameters.TotalCashPrice.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.TotalCashPrice.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.TotalCashPrice.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.TotalCashPrice.value">
                                    <p class="parameterValue">{{ offer.financeParameters.TotalCashPrice.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.DepositAmount">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.DepositAmount.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.DepositAmount.parameterDescription.parameterName">
                                        {{ offer.financeParameters.DepositAmount.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.DepositAmount.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.DepositAmount.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.DepositAmount.value">
                                    <p class="parameterValue">{{ offer.financeParameters.DepositAmount.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.Mileage">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.Mileage.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.Mileage.parameterDescription.parameterName">{{
                                        offer.financeParameters.Mileage.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.Mileage.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.Mileage.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap v-if="offer.financeParameters.Mileage.value">
                                    <p class="parameterValue">{{ offer.financeParameters.Mileage.value |
                                        formatParameter(offer.financeParameters.Mileage.parameterId,
                                        offer.financeParameters.Mileage.dataType,
                                        offer.financeParameters.Mileage.parameterDescription.parameterDisplayPattern,
                                        labels)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.Term">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.Term.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.Term.parameterDescription.parameterName">{{
                                        offer.financeParameters.Term.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.Term.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.Term.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap v-if="offer.financeParameters.Term.value">
                                    <p class="parameterValue">{{ offer.financeParameters.Term.value |
                                        formatParameter(offer.financeParameters.Term.parameterId,
                                        offer.financeParameters.Term.dataType,
                                        offer.financeParameters.Term.parameterDescription.parameterDisplayPattern,
                                        labels)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.APR">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.APR.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.APR.parameterDescription.parameterName">{{
                                        offer.financeParameters.APR.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.APR.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.APR.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap v-if="offer.financeParameters.APR.value">
                                    <p class="parameterValue">{{ offer.financeParameters.APR.value |
                                        formatNumber('percentage', parameters.language, parameters.market)}}
                                        APR</p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.IncludeFreightPDI">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription.parameterName">
                                        {{ offer.financeParameters.IncludeFreightPDI.parameterDescription.parameterName
                                        }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.IncludeFreightPDI.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.IncludeFreightPDI.value">
                                    <p class="parameterValue">{{ formatSummary(offer.financeParameters.IncludeFreightPDI.value, 'boolean', this.parameters.language, this.parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.IncludeTaxes">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.IncludeTaxes.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.IncludeTaxes.parameterDescription.parameterName">{{
                                        offer.financeParameters.IncludeTaxes.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.IncludeTaxes.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.IncludeTaxes.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap v-if="offer.financeParameters.IncludeTaxes.value">
                                    <p class="parameterValue">{{ formatSummary(offer.financeParameters.IncludeTaxes.value, 'boolean', this.parameters.language, this.parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.DeliveryCredit">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.DeliveryCredit.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.DeliveryCredit.parameterDescription.parameterName">
                                        {{ offer.financeParameters.DeliveryCredit.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.DeliveryCredit.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.DeliveryCredit.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.DeliveryCredit.value">
                                    <p class="parameterValue">{{ offer.financeParameters.DeliveryCredit.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.EstTaxesAndFees">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription.parameterName">
                                        {{ offer.financeParameters.EstTaxesAndFees.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.EstTaxesAndFees.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.EstTaxesAndFees.value">
                                    <p class="parameterValue">{{ offer.financeParameters.EstTaxesAndFees.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.TotAmtDueOnSign">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription.parameterName">
                                        {{ offer.financeParameters.TotAmtDueOnSign.parameterDescription.parameterName }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.TotAmtDueOnSign.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.TotAmtDueOnSign.value">
                                    <p class="parameterValue">{{ offer.financeParameters.TotAmtDueOnSign.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.ImplicitFinanceChg">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription.parameterName">
                                        {{ offer.financeParameters.ImplicitFinanceChg.parameterDescription.parameterName
                                        }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.ImplicitFinanceChg.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.ImplicitFinanceChg.value">
                                    <p class="parameterValue">{{ offer.financeParameters.ImplicitFinanceChg.value |
                                        formatNumber('currencyInteger', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" v-if="offer.financeParameters.ExcessMileageRate">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.ExcessMileageRate.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.ExcessMileageRate.parameterDescription.parameterName">
                                        {{ offer.financeParameters.ExcessMileageRate.parameterDescription.parameterName
                                        }}
                                        <v-tooltip bottom class="tooltip"
                                                   v-if="offer.financeParameters.ExcessMileageRate.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span>{{ offer.financeParameters.ExcessMileageRate.parameterDescription.description }}</span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                                <v-flex desktop4 laptop4 tablet4 wrap
                                        v-if="offer.financeParameters.ExcessMileageRate.value">
                                    <p class="parameterValue">{{ offer.financeParameters.ExcessMileageRate.value |
                                        formatNumber('currency', parameters.language, parameters.market)
                                        }} </p>
                                </v-flex>
                            </v-layout>
                            <v-layout row class="parameterRow" id="paymentSummary"
                                      v-if="offer.financeParameters.BreakdownOfPayments">
                                <v-flex desktop8 laptop8 tablet8 wrap
                                        v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription">
                                    <p class="parameterName"
                                       v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription.parameterName">
                                        {{
                                        offer.financeParameters.BreakdownOfPayments.parameterDescription.parameterName
                                        }}
                                        <v-tooltip bottom
                                                   v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription.description">
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="info-icon" v-bind="attrs" v-on="on">
                                                    <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                         focusable="false"
                                                         style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                        <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                            <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                                    stroke-width="2"></circle>
                                                            <path fill="#4D4D4D"
                                                                  d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                        </g>
                                                    </svg>
                                                </div>
                                            </template>
                                            <span v-html="paymentSummary(offer.financeParameters, offer.financeParameters.BreakdownOfPayments.parameterDescription.description)"></span>
                                        </v-tooltip>
                                    </p>
                                </v-flex>
                            </v-layout>
                        </td>
                    </tr>
                </table>
            </mq-layout>
            <mq-layout mq="xs">
                <table class="financeParameters" v-if="offer.financeParameters" v-model="showTooltips">
                    <tr>
                        <th></th>
                        <th></th>
                    </tr>                
                    <tr>
                        <td>
                            <p class="productName">{{
                                offer.financeProductDescription.productName }}</p>
                        </td>
                    </tr>
                    <tr>
                        <td class="financeParametersHeadline">
                            <p class="headline">{{ labels.engineSelectionPage.calculationInDetail }}</p>
                        </td>
                    </tr>
                    <tr class="parameterRow">
                        <td v-if="parameters.province" class="parameterName">
                            <p>Region</p>
                        </td>
                        <td v-if="parameters.province" class="parameterValue">
                            <p>{{ parameters.province }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.TotalCashPrice">
                        <td
                                v-if="offer.financeParameters.TotalCashPrice.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.TotalCashPrice.parameterDescription.parameterName">{{
                                offer.financeParameters.TotalCashPrice.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.TotalCashPrice.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.TotalCashPrice.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.TotalCashPrice.value" class="parameterValue">
                            <p>{{ offer.financeParameters.TotalCashPrice.value |
                                formatNumber('currencyInteger',
                                parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.DepositAmount">
                        <td v-if="offer.financeParameters.DepositAmount.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.DepositAmount.parameterDescription.parameterName">{{
                                offer.financeParameters.DepositAmount.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.DepositAmount.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.DepositAmount.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.DepositAmount.value" class="parameterValue">
                            <p>{{ offer.financeParameters.DepositAmount.value |
                                formatNumber('currencyInteger',
                                parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.Mileage">
                        <td v-if="offer.financeParameters.Mileage.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.Mileage.parameterDescription.parameterName">
                                {{ offer.financeParameters.Mileage.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.Mileage.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.Mileage.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.Mileage.value" class="parameterValue">
                            <p>{{ offer.financeParameters.Mileage.value |
                                formatParameter(offer.financeParameters.Mileage.parameterId,
                                offer.financeParameters.Mileage.dataType,
                                offer.financeParameters.Mileage.parameterDescription.parameterDisplayPattern,
                                labels)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.Term">
                        <td v-if="offer.financeParameters.Term.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.Term.parameterDescription.parameterName">{{
                                offer.financeParameters.Term.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.Term.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.Term.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.Term.value" class="parameterValue">
                            <p>{{ offer.financeParameters.Term.value |
                                formatParameter(offer.financeParameters.Term.parameterId,
                                offer.financeParameters.Term.dataType,
                                offer.financeParameters.Term.parameterDescription.parameterDisplayPattern,
                                labels)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.APR">
                        <td v-if="offer.financeParameters.APR.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.APR.parameterDescription.parameterName">{{
                                offer.financeParameters.APR.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.APR.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.APR.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.APR.value" class="parameterValue">
                            <p>{{ offer.financeParameters.APR.value |formatNumber('percentage',
                                parameters.language, parameters.market)
                                }} APR</p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.IncludeFreightPDI">
                        <td
                                v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription.parameterName">{{
                                offer.financeParameters.IncludeFreightPDI.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.IncludeFreightPDI.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.IncludeFreightPDI.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.IncludeFreightPDI.value" class="parameterValue">
                            <p>{{ formatSummary(offer.financeParameters.IncludeFreightPDI.value, 'boolean', this.parameters.language, this.parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.IncludeTaxes">
                        <td v-if="offer.financeParameters.IncludeTaxes.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.IncludeTaxes.parameterDescription.parameterName">{{
                                offer.financeParameters.IncludeTaxes.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.IncludeTaxes.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.IncludeTaxes.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.IncludeTaxes.value" class="parameterValue">
                            <p>{{ formatSummary(offer.financeParameters.IncludeTaxes.value, 'boolean', this.parameters.language, this.parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.DeliveryCredit">
                        <td
                                v-if="offer.financeParameters.DeliveryCredit.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.DeliveryCredit.parameterDescription.parameterName">{{
                                offer.financeParameters.DeliveryCredit.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.DeliveryCredit.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.DeliveryCredit.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.DeliveryCredit.value" class="parameterValue">
                            <p>{{ offer.financeParameters.DeliveryCredit.value |
                                formatNumber('currencyInteger',
                                parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.EstTaxesAndFees">
                        <td
                                v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription.parameterName">{{
                                offer.financeParameters.EstTaxesAndFees.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.EstTaxesAndFees.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.EstTaxesAndFees.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.EstTaxesAndFees.value" class="parameterValue">
                            <p>{{ offer.financeParameters.EstTaxesAndFees.value |
                                formatNumber('currencyInteger', parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.TotAmtDueOnSign">
                        <td
                                v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription.parameterName">{{
                                offer.financeParameters.TotAmtDueOnSign.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.TotAmtDueOnSign.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.TotAmtDueOnSign.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.TotAmtDueOnSign.value" class="parameterValue">
                            <p>{{ offer.financeParameters.TotAmtDueOnSign.value |
                                formatNumber('currencyInteger', parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.ImplicitFinanceChg">
                        <td
                                v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription.parameterName">{{
                                offer.financeParameters.ImplicitFinanceChg.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.ImplicitFinanceChg.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.ImplicitFinanceChg.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.ImplicitFinanceChg.value" class="parameterValue">
                            <p>{{ offer.financeParameters.ImplicitFinanceChg.value |
                                formatNumber('currencyInteger', parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" v-if="offer.financeParameters.ExcessMileageRate">
                        <td
                                v-if="offer.financeParameters.ExcessMileageRate.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.ExcessMileageRate.parameterDescription.parameterName">{{
                                offer.financeParameters.ExcessMileageRate.parameterDescription.parameterName }}
                                <v-tooltip bottom class="tooltip" :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.ExcessMileageRate.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span>{{ offer.financeParameters.ExcessMileageRate.parameterDescription.description }}</span>
                                </v-tooltip>
                            </p>
                        </td>
                        <td v-if="offer.financeParameters.ExcessMileageRate.value" class="parameterValue">
                            <p>{{ offer.financeParameters.ExcessMileageRate.value |
                                formatNumber('currency', parameters.language, parameters.market)
                                }} </p>
                        </td>
                    </tr>
                    <tr class="parameterRow" id="paymentSummary" v-if="offer.financeParameters.BreakdownOfPayments">
                        <td
                                v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription" class="parameterName">
                            <p
                               v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription.parameterName">{{
                                offer.financeParameters.BreakdownOfPayments.parameterDescription.parameterName }}
                                <v-tooltip bottom :open-on-hover="false" :open-on-click="true"
                                           v-if="offer.financeParameters.BreakdownOfPayments.parameterDescription.description">
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="info-icon" v-bind="attrs" v-on="on">
                                            <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet"
                                                 focusable="false"
                                                 style="pointer-events: none; display: block; width: 100%; height: 100%;">
                                                <g fill="none" fill-rule="evenodd" viewBox="0 0 24 24">
                                                    <circle cx="12" cy="12" r="11" stroke="#BBB"
                                                            stroke-width="2"></circle>
                                                    <path fill="#4D4D4D"
                                                          d="M11 16.09h1.818v-5.555H11v5.556zm0-7.272h1.818V7H11v1.818z"></path>
                                                </g>
                                            </svg>
                                        </div>
                                    </template>
                                    <span v-html="paymentSummary(offer.financeParameters, offer.financeParameters.BreakdownOfPayments.parameterDescription.description)"></span>
                                </v-tooltip>
                            </p>
                        </td>
                    </tr>
                </table>
            </mq-layout>
        </div>
        <div id="finalSelection">
            <div>
                <p class="summary">{{ labels.engineSelectionPage.forExampleTotalInstallment }} <span class="h2inline">{{ offer.financePaymentInformation.basePayment |formatNumber('currencyInteger', parameters.language, parameters.market) }}</span>
                    {{labels.engineSelectionPage.totalInstallmentPerMonth }}
                </p>
            </div>
            <div class="continue">
                <v-btn dark tile depressed color="#262626" :href="labels.engineSelectionPage.requestTestDriveURL"
                       target="_blank" @click="sendRequestTestDriveTrackingEvent">{{
                    labels.engineSelectionPage.requestTestDrive }}
                </v-btn>
                <v-btn dark tile depressed color="#1C69D4" :href="configuratorUrl"
                       @click="sendOpenConfiguratorTrackingEvent">
                    {{ labels.engineSelectionPage.goToConfigurator }}
                </v-btn>
            </div>

        </div>
        <div id="disclaimer">
            <p class="disclaimer" v-html="'*' + offer.financeProductDescription.disclaimerText"></p>
        </div>
    </div>
</template>

<script>
    import TrackingHandler from "../helpers/TrackingHandler";

    export default {
        name: 'engineSelectionItemCost',
        components: {},
        props: {
            offer: {},
        },
        data() {
            return {
                showTooltips: true
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            filterSettings() {
                return this.$store.state.filterSettings
            },
            configuratorUrl() {
                let baseUrl = 'https://configure.bmw.'
                let language = this.$store.getters.parameters.language
                let market = this.$store.getters.parameters.market
                let modelRange = this.offer.vehicleInformation.modelRange
                let modelCode = this.offer.vehicleInformation.code
                let vehicleIdInformation = this.offer.vehicleInformation.vehicleId.split('_')
                let transmissionCode = vehicleIdInformation[1]
                let configuratorUrl = baseUrl + market + '/' + language + '_' + market.toUpperCase() + '/configure/' + modelRange + '/' + modelCode + '/' + transmissionCode
                return configuratorUrl
            },
        },
        mounted() {

        },
        methods: {
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            paymentSummary(parameters, description) {
                let paymentSummary = description
                if (description.includes('$ ')) {
                    paymentSummary = paymentSummary.replace(/\$ /g, '')
                }
                if (description.includes(' $')) {
                    paymentSummary = paymentSummary.replace(/ \$/g, '')
                }
                if (description.includes('{APR}')) {
                    paymentSummary = paymentSummary.replace('{APR}', this.formatSummary(parameters.APR.value, 'percentage', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{BalloonPayment}')) {
                    paymentSummary = paymentSummary.replace('{BalloonPayment}', this.formatSummary(parameters.BalloonPayment.value, 'percentage', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{CanadaACRPercentage}')) {
                    paymentSummary = paymentSummary.replace('{CanadaACRPercentage}', this.formatSummary(parameters.CanadaACRPercentage.value, 'percentage', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{DeliveryCredit}')) {
                    paymentSummary = paymentSummary.replace('{DeliveryCredit}', this.formatSummary(parameters.DeliveryCredit.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{DepositAmount}')) {
                    paymentSummary = paymentSummary.replace('{DepositAmount}', this.formatSummary(parameters.DepositAmount.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{EstTaxesAndFees}')) {
                    paymentSummary = paymentSummary.replace('{EstTaxesAndFees}', this.formatSummary(parameters.EstTaxesAndFees.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ExcessMileageRate}')) {
                    paymentSummary = paymentSummary.replace('{ExcessMileageRate}', this.formatSummary(parameters.ExcessMileageRate.value, 'currency', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{FirstMonthlyPayment}')) {
                    paymentSummary = paymentSummary.replace('{FirstMonthlyPayment}', this.formatSummary(parameters.FirstMonthlyPayment.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{FirstMonthlyPayment}')) {
                    paymentSummary = paymentSummary.replace('{FirstMonthlyPayment}', this.formatSummary(parameters.FirstMonthlyPayment.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{FlexPayFreq}')) {
                    paymentSummary = paymentSummary.replace('{FlexPayFreq}', this.formatSummary(parameters.FlexPayFreq.value, 'localizedString', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{FreightPDIExcluded}')) {
                    paymentSummary = paymentSummary.replace('{FreightPDIExcluded}', this.formatSummary(parameters.FreightPDIExcluded.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ImplicitFinanceChg}')) {
                    paymentSummary = paymentSummary.replace('{ImplicitFinanceChg}', this.formatSummary(parameters.ImplicitFinanceChg.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{IncludeFrightPDI}')) {
                    paymentSummary = paymentSummary.replace('{IncludeFrightPDI}', this.formatSummary(parameters.IncludeFrightPDI.value, 'boolean', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{IncludeTaxes}')) {
                    paymentSummary = paymentSummary.replace('{IncludeTaxes}', this.formatSummary(parameters.IncludeTaxes.value, 'boolean', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{InspectionAndFreight}')) {
                    paymentSummary = paymentSummary.replace('{InspectionAndFreight}', this.formatSummary(parameters.InspectionAndFreight.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{MSRP}')) {
                    paymentSummary = paymentSummary.replace('{MSRP}', this.formatSummary(parameters.MSRP.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{Mileage}')) {
                    paymentSummary = paymentSummary.replace('{Mileage}', this.formatSummary(parameters.Mileage.value, 'integer', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{MonthlyLeasePayment}')) {
                    paymentSummary = paymentSummary.replace('{MonthlyLeasePayment}', this.formatSummary(parameters.MonthlyLeasePayment.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{MonthlyPayment}')) {
                    paymentSummary = paymentSummary.replace('{MonthlyPayment}', this.formatSummary(parameters.MonthlyPayment.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{MonthlyPaymentAmount}')) {
                    paymentSummary = paymentSummary.replace('{MonthlyPaymentAmount}', this.formatSummary(parameters.MonthlyPaymentAmount.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{NonFinancedFees}')) {
                    paymentSummary = paymentSummary.replace('{NonFinancedFees}', this.formatSummary(parameters.NonFinancedFees.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{NumberOfPayments}')) {
                    paymentSummary = paymentSummary.replace('{NumberOfPayments}', this.formatSummary(parameters.NumberOfPayments.value, 'integer', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ProtectProdAmt}')) {
                    paymentSummary = paymentSummary.replace('{ProtectProdAmt}', this.formatSummary(parameters.ProtectProdAmt.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ProtectProdList}')) {
                    paymentSummary = paymentSummary.replace('{ProtectProdList}', this.formatSummary(parameters.ProtectProdList.value, 'localizedString', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ProtectProdTax}')) {
                    paymentSummary = paymentSummary.replace('{ProtectProdTax}', this.formatSummary(parameters.ProtectProdTax.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ProtectProdTotal}')) {
                    paymentSummary = paymentSummary.replace('{ProtectProdTotal}', this.formatSummary(parameters.ProtectProdTotal.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{ProvinceName}')) {
                    paymentSummary = paymentSummary.replace('{ProvinceName}', this.parameters.province)
                }
                if (description.includes('{ResidualValue}')) {
                    paymentSummary = paymentSummary.replace('{ResidualValue}', this.formatSummary(parameters.ResidualValue.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{SecurityDeposit}')) {
                    paymentSummary = paymentSummary.replace('{SecurityDeposit}', this.formatSummary(parameters.SecurityDeposit.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{SelectedOptions}')) {
                    paymentSummary = paymentSummary.replace('{SelectedOptions}', this.formatSummary(parameters.SelectedOptions.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{Term}')) {
                    paymentSummary = paymentSummary.replace('{Term}', this.formatSummary(parameters.Term.value, 'integer', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotAmtDueOnSign}')) {
                    paymentSummary = paymentSummary.replace('{TotAmtDueOnSign}', this.formatSummary(parameters.TotAmtDueOnSign.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotIntOverTerm}')) {
                    paymentSummary = paymentSummary.replace('{TotIntOverTerm}', this.formatSummary(parameters.TotIntOverTerm.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotalCashPrice}')) {
                    paymentSummary = paymentSummary.replace('{TotalCashPrice}', this.formatSummary(parameters.TotalCashPrice.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotalGSTAndHST}')) {
                    paymentSummary = paymentSummary.replace('{TotalGSTAndHST}', this.formatSummary(parameters.TotalGSTAndHST.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotalMonthlyPayment}')) {
                    paymentSummary = paymentSummary.replace('{TotalMonthlyPayment}', this.formatSummary(parameters.TotalMonthlyPayment.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotalObligation}')) {
                    paymentSummary = paymentSummary.replace('{TotalObligation}', this.formatSummary(parameters.TotalObligation.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TotalVehPrice}')) {
                    paymentSummary = paymentSummary.replace('{TotalVehPrice}', this.formatSummary(parameters.TotalVehPrice.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TradeInAmount}')) {
                    paymentSummary = paymentSummary.replace('{TradeInAmount}', this.formatSummary(parameters.TradeInAmount.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                if (description.includes('{TradeInDownPmtTot}')) {
                    paymentSummary = paymentSummary.replace('{TradeInDownPmtTot}', this.formatSummary(parameters.TradeInDownPmtTot.value, 'currencyInteger', this.parameters.language, this.parameters.market))
                }
                return paymentSummary
            },
            formatSummary: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')
                if (value.includes('$')) {
                    value = value.replace('$ ', '')
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {style: 'currency', currency: currency}).format(value)
                }
                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }
                if (type == 'boolean') {
                    if (language == 'en') {
                        if (value == 'true') {
                            return 'Yes'
                        }
                        if (value == 'false') {
                            return 'No'
                        }
                    }
                    if (language == 'fr') {
                        if (value == 'true') {
                            return 'Oui'
                        }
                        if (value == 'false') {
                            return 'Non'
                        }
                    }
                }
            },
            sendRequestTestDriveTrackingEvent() {
                TrackingHandler.sendRequestTestDriveEvent(Date.now(), this.$store, this.offer);
            },
            sendOpenConfiguratorTrackingEvent() {
                TrackingHandler.sendOpenConfiguratorEvent(Date.now(), this.$store, this.offer);
            },
        },
        filters: {
            replaceFPTermWithValue: function (value, trm) {
                if (!value) {
                    return ''
                }
                if (!value.search('{Term}')) {
                    value = value.replace('{Term}', trm)
                    return value
                } else {
                    return value
                }
            },
            formatParameter: function (value, id, type, pattern, labels) {
                if (type == 'Integer') {
                    value = parseInt(value)
                    if (id == 'Mileage') {
                        return value + ' ' + labels.financeParameterFormat.mileage
                    }
                    if (id == 'Term') {
                        return value + ' ' + labels.financeParameterFormat.term
                    }
                    return value
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    // value = value.replace('.', ',')
                    let splitValue = value.split('.')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + ',' + hundreds + '.' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' ' + labels.financeParameterFormat.currencySymbol
                }
                if (type == 'Boolean') {
                    if (value == 'true') {
                        return 'Yes'
                    }
                    if (value == 'false') {
                        return 'No'
                    }
                }
            },
            lineBreak0: function (value) {
                if (!value) {
                    return ''
                }
                value = value.replace('<br/>', '_')
                value = value.split('_')
                return value[0]

            },
            lineBreak1: function (value) {
                if (!value) {
                    return ''
                }
                value = value.replace('<br/>', '_')
                value = value.split('_')
                return value[1]
            },
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
            formatNumber: function (value, type, language, market) {
                if (!value) {
                    return ''
                }
                let locale = language + '-' + market.toUpperCase()
                let currency = (function () {
                    switch (locale) {
                        case 'de-DE':
                            return 'EUR'
                        case 'en-CA':
                            return 'CAD'
                        case 'fr-CA':
                            return 'CAD'
                    }
                })('EUR')

                if (type == 'currency') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(value)
                }

                if (type == 'currencyInteger') {
                    return new Intl.NumberFormat(locale, {
                        style: 'currency',
                        currency: currency,
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                    }).format(value)
                }

                if (type == 'percentage') {
                    return new Intl.NumberFormat(locale, {
                        style: 'percent',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }).format(value)
                }

            },
        }
    }
</script>

<style scoped>
    .vehicleOffer {
        background-color: white;
        box-shadow: 0 0 5px lightgrey;
        padding: 20px 20px;
        margin-bottom: 20px;
    }

    .vehicleInformation, .offerDetails {
        border-bottom: lightgray solid thin;
        margin-bottom: 15px;
        padding-bottom: 10px;
    }

    .flex-row {
        margin-left: 0px;
    }

    .parameterRow {
        margin-left: 0px;
        margin-right: 0px;
    }

    .headline {
        color: #8E8E8E;
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 10px;
    }

    .informationLast {
        margin-top: 3px;
    }

    p {
        font-size: 15px;
        letter-spacing: 0.22px;
    }

    #finalSelection a {
        font-size: 14px;
        letter-spacing: 0.20px;
        margin-bottom: 3px;
        text-transform: none !important;
        padding: 0px 50px;
        height: 50px;
        width: 315px;
    }

    #mobile a {
        margin-bottom: 10px;
    }

    #default a {
        margin-left: 10px;
    }

    .vehicleInformation .flex-row {
        margin-bottom: 5px;
    }

    .productName {
        margin-bottom: 15px;
    }

    .financeParameters {
        width: 100%;
    }

    .financeParametersHeadline {
        vertical-align: top !important;
        width: 225px;
    }

    .parameterValue {
        text-align: right;
    }

    #default .parameterName {
        /*width: 432px;*/
    }

    #mobile .parameterName {
        width: 60%;
        margin-right: 10px;
    }

    #mobile .parameterValue {
        vertical-align: bottom;
    }

    #default button {
        margin-bottom: 10px;
        margin-top: 10px;
    }

    #finalSelection button {
        margin-right: 10px;
    }

    .v-card__actions button {
        margin-bottom: 0px;
    }

    .summary {
        float: right;
        clear: right;
    }

    .continue {
        float: right;
        clear: right;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    #mobile .summary {
        margin-bottom: 10px;
    }

    #mobile .continue {
        float: none;
        margin: auto;
    }

    #finalSelection button {
        color: white;
        text-decoration-line: unset;
    }

    #disclaimer {
        clear: right;
    }

    .h2inline {
        font-size: 25px;
        letter-spacing: 0.32px;
    }

    h2, h3, p, input {
        color: #262626;
    }

    h3 {
        margin-bottom: 15px;
    }

    .disclaimer {
        font-size: 12px;
        letter-spacing: 0.16px;
        line-height: 150%;
    }

    .info-row {
        min-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 12px;
        text-align: left;
        display: flex;
        align-items: center;
        font-size: 15px;
        letter-spacing: 0.22px;
        margin-right: 5px;
    }

    .info-icon {
        height: 12px;
        width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: top;
        margin-right: 5px;
        margin-left: 0px;
        /*display: flex;*/
        align-items: center;
    }

    .v-tooltip__content {
        color: #262626 !important;
        background-color: white !important;
        box-shadow: 0px 0px 5px lightgrey;
        opacity: 1 !important;
        max-width: 300px;
    }

    #paymentSummary {

    }

    #payments {
        color: #262626 !important;
        background-color: white !important;
        box-shadow: 0px 0px 5px lightgrey;
        opacity: 1 !important;
        width: 600px;
    }


</style>

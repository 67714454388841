<template>
    <div class="gridrow" v-if="labels.mainPage.vehiclesList" id="vehicleGrid">
        <v-layout row class="vehicle-tile">
            <v-flex v-for="(modelRange, index) in modelStats" :key="modelRange.modelsFoundPerSeries" md2 sm2 xs12 wrap class="vehicle-element">
                <div class="tile">
                    <div class="header">
                        <img v-bind:src="modelImageMobile(modelRange)" alt="model image" @error="imageUrl='./imagenotfound.jpg'"/>
                        <h3>{{ modelRange.rangeDescription }}</h3>
                    </div>
                    <div class="transmissions" v-if="parameters.brand != 'bmwBike'">
                        <span>{{ modelRange.transmissionsFound }} </span>
                        <span v-show="modelRange.transmissionsFound == 0">{{ labels.mainPage.vehiclesList.enginesFoundPlural }}</span>
                        <span v-show="modelRange.transmissionsFound == 1">{{ labels.mainPage.vehiclesList.enginesFoundSingular }}</span>
                        <span v-show="modelRange.transmissionsFound > 1">{{ labels.mainPage.vehiclesList.enginesFoundPlural }}</span>
                    </div>
                    <div class="modelDetails" v-if="labels.mainPage.vehiclesList.listDisplayEfficiency == true">
                        <VehicleGridItemEfficiency :modelRange="modelRange"></VehicleGridItemEfficiency>
                        <span v-if="series != 'i' && parameters.brand == 'bmw'"
                              class="engineSelectionButton">
                        <EngineSelectionPage :modelSeries=series
                                             :modelRange=index
                                             :rangeDescription=modelRange.rangeDescription
                                             :modelImages="modelRange.vehicleImages"
                        ></EngineSelectionPage>
                        </span>
                        <span v-if="series == 'i'"
                              class="engineSelectionButton">
                        <EngineSelectionPageElectric :modelSeries=series
                                                     :modelRange=index
                                                     :rangeDescription=modelRange.rangeDescription
                                                     :modelImages="modelRange.vehicleImages"
                        ></EngineSelectionPageElectric>
                        </span>
                        <span v-if="parameters.brand == 'bmwBike'"
                              class="engineSelectionButton">
                        <EngineSelectionPageMoto :modelSeries=series
                                                 :modelRange=index
                                                 :rangeDescription=modelRange.rangeDescription
                                                 :modelImages="modelRange.vehicleImages"
                        ></EngineSelectionPageMoto>
                        </span>
                    </div>
                    <div class="modelDetails" v-if="labels.mainPage.vehiclesList.listDisplayCost == true">
                        <VehicleGridItemCost :modelSeries=series
                                             :modelRange=index
                                             :modelRangeObject="modelRange"
                                             :rangeDescription=modelRange.rangeDescription
                                             :modelImageMobile="modelImageMobile(modelRange)"
                                             :modelImageDesktop="modelImageDesktop(modelRange)"></VehicleGridItemCost>
                        <router-link tag="button" dark tile depressed color="#1C69D4" :to="{ name: 'EngineSelectionPageCost', params: {modelSeries: series, modelRange: index, rangeDescription: modelRange.rangeDescription, enginesFound: modelRange.transmissionsFound, modelImageMobile: modelImageMobile(modelRange), modelImageDesktop: modelImageDesktop(modelRange) } }">
                            {{ labels.mainPage.vehiclesList.chooseEngine }}
                        </router-link>
                    </div>
                </div>
            </v-flex>
        </v-layout>

    </div>

</template>

<script>
    import EngineSelectionPage from "./EngineSelectionPage";
    import EngineSelectionPageCost from "./EngineSelectionPageCost";
    import EngineSelectionPageElectric from "./EngineSelectionPageElectric";
    import EngineSelectionPageMoto from "./MotoOfferDetails";
    import VehicleGridItemCost from "./VehicleGridItemCost";
    import VehicleGridItemEfficiency from "./VehicleGridItemEfficiency";

    export default {
        name: 'VehicleGridItem',
        components: {
            VehicleGridItemEfficiency,
            VehicleGridItemCost,
            EngineSelectionPage,
            EngineSelectionPageCost,
            EngineSelectionPageElectric,
            EngineSelectionPageMoto,
        },
        props: {
            modelStats: {},
            series: '',
        },
        data() {
            return {}
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            parameters() {
                return this.$store.state.parameters
            },
            modelStatsOverview() {
                return this.$store.state.bucketStats.modelStatsOverview
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },
        },
        created() {
        },
        methods: {
            modelImageDesktop(modelRange) {
                if (modelRange.vehicleImages) {
                    if (modelRange.vehicleImages.desktop) {
                        return modelRange.vehicleImages.desktop
                    }
                    else {
                        return  "/imagenotfound.jpg"
                    }
                }
                else {
                    return "/imagenotfound.jpg"
                }
            },
            modelImageMobile(modelRange) {
                if (modelRange.vehicleImages) {
                    if (modelRange.vehicleImages.mobile) {
                        return modelRange.vehicleImages.mobile
                    }
                    else {
                        return  "/imagenotfound.jpg"
                    }
                }
                else {
                    return "/imagenotfound.jpg"
                }
            },
            infoIconBtnClick() {
                console.log('Icon clicked: Info Icon')
            },
            modelNVehicles(modelRange) {
                const filteredModelNOffers = this.seriesNOffers.filter((offer) => {
                    return offer.vehicleInformation.modelRange.includes(modelRange);
                })
                const modelNVehicles = [];
                const vehicles = new Map();
                for (const offer of filteredModelNOffers) {
                    if (!vehicles.has(offer.vehicleInformation.vehicleId)) {
                        vehicles.set(offer.vehicleInformation.vehicleId, true);
                        modelNVehicles.push({
                            id: offer.vehicleInformation.vehicleId,
                            data: offer
                        });
                    }
                }
                return modelNVehicles;
            },
        },
        filters: {
            formatValue: function (value, type) {
                if (type == 'Integer') {
                    return parseInt(value)
                }
                if (type == 'Float') {
                    return parseFloat(value).toFixed(1).replace('.', ',')
                }
                if (type == 'Percentage') {
                    value = parseFloat(value * 100).toFixed(2)
                    value = value.replace('.', ',')
                    return value + ' %'
                }
                if (type == 'Currency') {
                    value = parseFloat(value).toFixed(2)
                    value = value.replace('.', ',')
                    let splitValue = value.split(',')
                    if (splitValue[0].length > 3) {
                        let thousands = splitValue[0].slice(0, -3)
                        let hundreds = splitValue[0].slice(-3)
                        return thousands + '.' + hundreds + ',' + splitValue[1] + ' ' + labels.financeParameterFormat.currencySymbol
                    }
                    return value + ' €'
                }
            },
            calculatePS: function (value, kW) {
                return parseInt(kW * 1.35962)
            },
            replaceFuelType: function (value, fuelType) {
                value = value.replace('O', 'Benzin')
                value = value.replace('E', 'Voll-Elektrisch')
                return value
            },
            replaceModelsWithValue: function (value, modelsFound) {
                if (!value) {
                    return ''
                }
                if (value.search('{models}')) {
                    value = value.replace('{models}', modelsFound)
                    return value
                } else {
                    return value
                }
            },
            replaceTermWithValue: function (value, term) {
                if (!value) {
                    return ''
                }
                if (value.search('{term}')) {
                    value = value.replace('{term}', term)
                    return value
                } else {
                    return value
                }
            },
            replaceMileageWithValue: function (value, mileage) {
                if (!value) {
                    return ''
                }
                if (value.search('{mileage}')) {
                    value = value.replace('{mileage}', mileage)
                    return value
                } else {
                    return value
                }
            },
            replaceDepositWithValue: function (value, deposit) {
                if (!value) {
                    return ''
                }
                if (value.search('{deposit}')) {
                    value = value.replace('{deposit}', deposit)
                    return value
                } else {
                    return value
                }
            },
            replaceProductBaseTypeWithValue: function (value, productBaseType) {
                if (!value) {
                    return ''
                }
                if (value.search('{productBaseType}')) {
                    value = value.replace('{productBaseType}', productBaseType)
                    return value
                } else {
                    return value
                }
            },
            replaceMonthlyPaymentWithValue: function (value, monthlyPayment) {
                if (!value) {
                    return ''
                }
                if (value.search('{monthlyPayment}')) {
                    value = value.replace('{monthlyPayment}', monthlyPayment)
                    return value
                } else {
                    return value
                }
            },
        }
    }
</script>

<style scoped>
    .tile {
        display: block;
        width: 270px;
    }

    #default .vehicle-element {
        margin-right: 10px;
    }

    .flex {
        min-width: 270px;
    }

    .row {
        margin: 0px;
    }

    img {
        width: 270px;
        display: block;
        margin: 5px 0px;
    }

    h3 {
        word-wrap: break-word;
        white-space: normal;
        margin-bottom: 5px;
        width: 250px;
    }

    p, span {
        font-size: 12px;
        letter-spacing: 0.16px;
        margin-bottom: 3px;
    }

    .header {
        height: 200px;
        margin-top: 25px;
    }

    .transmissions {
        font-weight: bold;
        color: #8E8E8E;
        margin-top: 5px;
        margin-bottom: 10px;
    }

    .info-row {
        min-height: 12px;
        display: flex;
        align-items: center;
        margin-bottom: 3px;
    }

    .info-text {
        min-height: 12px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        letter-spacing: 0.16px;
    }

    .info-icon {
        height: 12px;
        width: 12px;
        min-width: 12px;
        justify-content: center;
        stroke: none;
        vertical-align: middle;
        margin-right: 15px;
        display: flex;
        align-items: center;
    }

    .seperator {
        margin: 0px 2px;
    }

    .emission {
        background-color: green;
    }

    #mobile .engineSelectionButton {
        display: block;
        margin-top: 10px;
    }

    #mobile .vehicle-tile {
        margin: 0;
    }

    button {
        color: white;
        background-color: #1C69D4;
        margin-top: 10px;
    }

    .minEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 5px;
        margin-right: 2px;
        height: 10px;
        display: flex;
    }

    .maxEnergyLabel {
        color: white;
        background-color: darkgrey;
        font-weight: bold;
        font-size: 8px;
        letter-spacing: 0.16px;
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 0.7%;
        margin-left: 2px;
        height: 5px;
        display: flex;
    }

    /* emission colors */

    .Aplus {
        background-color: #009036;
    }

    .A {
        background-color: #009642;
    }

    .B {
        background-color: #52ae34;
    }

    .C {
        background-color: #c6d300;
    }

    .D {
        background-color: #ffed00;
    }

    .E {
        background-color: #edae00;
    }

    .F {
        background-color: #ea670b;
    }

    .G {
        background-color: #e20c15;
    }
</style>

<template>
    <div data-app="true" v-if="labels.mainPage">
        <div id="baseTypeSelection">
            <h2>{{ labels.mainPage.productSelection.productSelectionTitle }}</h2>
            <p>{{ labels.mainPage.productSelection.productSelectionSubTitle }}</p>
            <v-layout row class="productSelection">
                <mq-layout mq="sm+">
                    <v-radio-group v-model="selectedBaseType" row>
                        <v-radio
                                v-for="(option, index) in productBaseTypes"
                                :key="index"
                                :label="option" :value="index" color="#1C69D4"></v-radio>
                    </v-radio-group>
                </mq-layout>
                <mq-layout mq="xs">
                    <v-radio-group v-model="selectedBaseType" column>
                        <v-radio
                                v-for="(option, index) in productBaseTypes"
                                :key="index"
                                :label="option" :value="index" color="#1C69D4"></v-radio>
                    </v-radio-group>
                </mq-layout>
            </v-layout>
            <p>{{ labels.mainPage.productSelection.productSelectionText }}</p>
        </div>
        <div id="adjustFinance">
            <AdjustFinancePage></AdjustFinancePage>
        </div>
    </div>

</template>

<script>
    import AdjustFinancePage from "./AdjustFinancePage"

    export default {
        name: 'MainFilterProductBaseType',
        components: {
            AdjustFinancePage
        },
        props: {},
        data() {
            return {
                selectedBaseType: 'ALL',
                productBaseTypes: {
                    'ALL': 'Alle Modelle',
                    'LEASE': 'Leasing',
                    'LOAN': 'Finanzierung'
                }
            }
        },
        computed: {
            labels() {
                return this.$store.state.labels
            },
            bucketStats() {
                return this.$store.state.bucketStats
            },

        },
        created() {
        },
        methods: {
            async updateOffers() {
                let baseUrl = this.$store.getters.parameters.baseUrl
                let market = this.$store.getters.parameters.market
                let brand = this.$store.getters.parameters.brand
                let channel = this.$store.getters.parameters.channel
                let language = this.$store.getters.parameters.language
                let baseProduct = this.selectedBaseType
                this.$store.commit('setBaseProduct', baseProduct)
                let trm = this.$store.getters.filterSettings.trm
                let dep = this.$store.getters.filterSettings.dep
                let mil = this.$store.getters.filterSettings.mil
                let min = this.$store.getters.filterSettings.min
                let max = this.$store.getters.filterSettings.max
                let bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                this.$store.commit('setBucketUrl', bucketUrl)
                await this.$store.dispatch('fetchBucketStats', bucketUrl)
            },
            /*filteredOffers() {
                let radioSelection = '';
                if (this.selectedProducts == 'productSelectionOption2') {
                    radioSelection = 'loan'
                } else if (this.selectedProducts == 'productSelectionOption3') {
                    radioSelection = 'lease'
                } else {
                    radioSelection = ''
                }
                if (this.offers != null) {
                    let filteredOffers = this.offers.filter((offer) => {
                        return offer.financeProduct.financeProductInformation.productBaseType.includes(radioSelection);
                    })
                    return filteredOffers;

                }
            }*/
        },
        watch: {
            selectedBaseType: {
                deep: true,
                handler() {
                    this.updateOffers()
                }
            }
        }
    }
</script>

<style scoped>
    .productSelection {
        margin-left: 0px;
    }

    #adjustFinance {
        margin-top: 10px;
        margin-bottom: 30px;
    }


</style>

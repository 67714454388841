<template>
    <div id="app" :class="isBrand">
        <div :id="mq" :class="isBrand">
            <router-view v-bind="props"></router-view>
        </div>
    </div>
</template>

<script>
    import VueMq from 'vue-mq'
    import Vue from 'vue'
    import MainPage from "./components/MainPage";
    import TrackingHandler from "./helpers/TrackingHandler";

    export default {
        name: 'App',
        components: {
            MainPage,
        },
        props: {},
        data() {
            return {
            }
        },
        computed: {
            mq() {
                return this.$mq == 'xs'
                    ? 'mobile'
                    : 'default'
            },
            props() {
                return {
                    rateMin: 0,
                    rateMax: 3000,
                    motoRateMin: 0,
                    motoRateMax: 600,
                    motoRateRange: [0,600],
                    segments: [],
                    isReturn: false
                }
            },
            parameters() {
                return this.$store.state.parameters
            },
            isBrand() {
                return this.parameters.brand
            },
            isCookieSet() {
                return false
            }
        },
        methods: {
            checkForCookie() {
                console.log(' each 1 second...');
            },
        },
        created() {
            initializeRegion(this.$store)
            this.$store.commit('setLoading', true)

            function getCookieValue(name) {
                if (document.cookie) {
                    let cookieArr = document.cookie.split(";");
                    for (let i = 0; i < cookieArr.length; i++) {
                        let cookiePair = cookieArr[i].split("=");
                        if (name == cookiePair[0].trim()) {
                            return decodeURIComponent(cookiePair[1]);
                        }
                    }
                } else {
                    return null;
                }
            }

            function Sleep(milliseconds) {
                return new Promise(resolve => setTimeout(resolve, milliseconds));
            }

            async function waitForCookie() {
                let regionLong = await getCookieValue('prov_consentCookie')
                while (regionLong == null) {
                    await Sleep(1000)
                    regionLong = await getCookieValue('prov_consentCookie')
                    if (regionLong != null) {
                        return regionLong
                    }
                }
            }

            async function initializeRegion(store) {
                let regionLong = await getCookieValue('prov_consentCookie')
                let path = window.location.pathname.split('/')
                if (path[3].toLowerCase().length > 2) {
                    let region = ''
                    store.commit('setRegion', region)
                    initializePage(store, region)
                }
                if (path[3].toLowerCase().length == 2) {
                    if (regionLong != null) {
                        let region = replaceRegionName(regionLong)
                        store.commit('setRegion', region)
                        initializePage(store, region)
                    }
                    else {
                        if(self==top) {
                            let region = path[3].toLowerCase()
                            store.commit('setRegion', region)
                            initializePage(store, region)
                        }
                        else {
                            let regionLong = await waitForCookie()
                            let region = replaceRegionName(regionLong)
                            store.commit('setRegion', region)
                            initializePage(store, region)
                        }
                    }
                }

                function replaceRegionName(regionLong) {
                    switch (regionLong) {
                        case 'alberta':
                            return 'ab'
                        case 'british-columbia':
                            return 'bc'
                        case 'manitoba':
                            return 'mb'
                        case 'new-brunswick':
                            return 'nb'
                        case 'newfoundland':
                            return 'nl'
                        case 'nova-scotia':
                            return 'ns'
                        case 'ontario':
                            return 'on'
                        case 'quebec':
                            return 'qc'
                        case 'saskatchewan':
                            return 'sk'
                        default:
                            return 'ab'
                    }
                }

                function replaceProvinceName(region, language) {
                    if (language == 'en') {
                        switch (region) {
                            case 'ab':
                                return 'Alberta'
                            case 'bc':
                                return 'British Columbia'
                            case 'mb':
                                return 'Manitoba'
                            case 'nb':
                                return 'New Brunswick'
                            case 'nl':
                                return 'Newfoundland and Labrador'
                            case 'ns':
                                return 'Nova Scotia'
                            case 'on':
                                return 'Ontario'
                            case 'qc':
                                return 'Quebec'
                            case 'sk':
                                return 'Saskatchewan'
                            default:
                                return 'Alberta'
                        }
                    }
                    if (language == 'fr') {
                        switch (region) {
                            case 'ab':
                                return 'Alberta'
                            case 'bc':
                                return 'Colombie-Britannique'
                            case 'mb':
                                return 'Manitoba'
                            case 'nb':
                                return 'Nouveau-Brunswick'
                            case 'nl':
                                return 'Terre-neuve-et-Labrador'
                            case 'ns':
                                return 'Nouvelle-Écosse'
                            case 'on':
                                return 'Ontario'
                            case 'qc':
                                return 'Québec'
                            case 'sk':
                                return 'Saskatchewan'
                            default:
                                return 'Alberta'
                        }
                    }
                }

                async function initializePage(store, region) {
                    if (self == top) {
                        store.commit('setSessionStorage', true)
                    }
                    if (self != top) {
                        store.commit('setSessionStorage', false)
                    }
                    let channel = 'sfamp'
                    store.commit('setChannel', channel)
                    let path = window.location.pathname.split('/')
                    let brand = path[1]
                    store.commit('setBrand', brand)
                    let market = path[2].substring(3, 5).toLowerCase()
                    store.commit('setMarket', market)
                    let language = path[2].substring(0, 2)
                    store.commit('setLanguage', language)
                    if (market == 'ca') {
                        store.commit('setProvince', replaceProvinceName(region, language))
                    }
                    let baseUrl = window.location.origin
                    if (window.location.origin == 'http://localhost:8080') {
                        baseUrl = process.env.VUE_APP_BASE_URL
                    }
                    store.commit('setBaseUrl', baseUrl)
                    let accessToken = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJhY3MifQ.-q83V-RvodyKuerRLEV_Q4mTY8NVUJrGmpuU226m1Z0'
                    store.commit('setAccessToken', accessToken)

                    let settingsUrl = ''
                    if (region.length == 2) {
                        settingsUrl = baseUrl + '/filter/settings/market/' + market + ':' + region + '/brand/' + brand + '/channel/' + channel
                    } else {
                        settingsUrl = baseUrl + '/filter/settings/market/' + market + '/brand/' + brand + '/channel/' + channel
                    }
                    store.commit('setSettingsUrl', settingsUrl)

                    let validityUrl = '';
                    if (region.length == 2) {
                        validityUrl = baseUrl + '/filter/fpvalidity/market/' + market + ':' + region + '/brand/' + brand + '/channel/' + channel
                    } else {
                        validityUrl = baseUrl + '/filter/fpvalidity/market/' + market + '/brand/' + brand + '/channel/' + channel
                    }
                    store.commit('setValidityUrl', validityUrl)

                    let labelsUrl = baseUrl + '/configuration/app/acs/market/' + market + '/brand/' + brand + '/channel/' + channel + '/' + language + '_' + market.toUpperCase()
                    store.commit('setLabelsUrl', labelsUrl)

                    store.dispatch('fetchLabels', labelsUrl)
                    await store.dispatch('fetchValidity', validityUrl)
                    await store.dispatch('fetchSettings', settingsUrl)

                    let baseProduct = 'ALL'
                    store.commit('setBaseProduct', baseProduct)
                    let selectedTab = 0
                    store.commit('setSelectedTab', selectedTab)
                    let selectedProduct = '1001'
                    store.commit('setSelectedProduct', selectedProduct)
                    let trm = store.getters.settings.filter.term.default
                    store.commit('setTermProduct1001', trm)
                    store.commit('setTerm', trm)
                    let mil = store.getters.settings.filter.mileage.default
                    store.commit('setMileageProduct1001', mil)
                    store.commit('setMileage', mil)
                    let dep = store.getters.settings.filter.deposit.default
                    store.commit('setDepositProduct1001', dep)
                    store.commit('setDeposit', dep)
                    let min = store.getters.settings.filter.rate.defaultMin
                    store.commit('setMinInstallment', min)
                    let max = store.getters.settings.filter.rate.defaultMax
                    store.commit('setMaxInstallment', max)
                    store.commit('setInstallmentRange', [min, max])
                    let cubeVersion = store.getters.settings.cubeVersion
                    store.commit('setCubeVersion', cubeVersion)
                    store.commit('setSegments', [])

                    let bucketUrl = ''
                    if (region.length == 2) {
                        bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + ':' + region + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                    } else if (market == 'de' && brand != 'bmwBike') {
                        bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?baseproduct=' + baseProduct
                    } else if (brand == 'bmwBike') {
                        bucketUrl = baseUrl + '/filter/models/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max
                    } else {
                        bucketUrl = baseUrl + '/filter/bucket/market/' + market.toLowerCase() + '/brand/' + brand.toLowerCase() + '/channel/' + channel + '/language/' + language + '/trm/' + trm + '/mil/' + mil + '/dep/' + dep + '/min/' + min + '/max/' + max + '?productid=' + selectedProduct
                    }
                    store.commit('setBucketUrl', bucketUrl)
                    await store.dispatch('fetchBucketStats', bucketUrl)
                    store.commit('setLoading', false)
                    TrackingHandler.sendAcsLoadedEvent(Date.now(), brand);
                }
            }
        }
    }
    Vue.use(VueMq, {
        breakpoints: {
            xs: 420,
            sm: 768,
            md: 1024,
            lg: 1280,
            xl: Infinity,
        },
        defaultBreakpoint: 'xs'
    })

</script>

<style>
    /*@font-face {
        font-family: 'BMWTypeNext';
        src: url(assets/BMWTypeNext-Thin.woff2);
        font-weight: 100;
    }

    @font-face {
        font-family: 'BMWTypeNext';
        src: url(assets/BMWTypeNext-Light.woff2);
        font-weight: 300;
    }*/

    @font-face {
        font-family: 'BMWTypeWeb';
        src: url(assets/bmwtypewebli_all.woff2);
        font-weight: normal;
    }

    @font-face {
        font-family: 'BMWTypeWeb';
        src: url(assets/bmwtypewebbo_all.woff2);
        font-weight: bold;
    }

    @font-face {
        font-family: 'BMWTypeNext';
        src: url(./assets/BMWTypeNext-Regular.woff2);
        font-weight: normal;
        /*font-weight: 400;*/
    }

    @font-face {
        font-family: 'BMWTypeNext';
        src: url(assets/BMWTypeNext-Bold.woff2);
        font-weight: bold;
        /*font-weight: 700;*/
    }

    @font-face {
        font-family: 'BMWMotorrad';
        src: url(assets/BMWMotorrad-Regular.otf);
        font-weight: normal;
    }

    @font-face {
        font-family: 'BMWMotorrad';
        src: url(assets/BMWMotorrad-Bold.otf);
        font-weight: bold;
    }

    #app {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        margin: auto;
        max-width: 100%;
        min-width: 320px;
        /*background-image: url(/sfamp_background.jpg);
        background-repeat: no-repeat;
        background-color: #1e2c45;*/
    }

    #app.bmwBike {
        padding-top: 100px;
    }

    #app.bmw {
        padding-top: 60px;
    }

    body {
        font-family: BMWTypeWeb;
        font-size: 15px;
        letter-spacing: 0.22px;
        color: #262626;
    }

    h1 {
        font-size: 35px;
        letter-spacing: 0.35px;
        font-weight: normal;
        margin-bottom: 10px;
    }

    h2 {
        font-size: 25px;
        letter-spacing: 0.32px;
        font-weight: normal;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 20px;
        letter-spacing: 0.27px;
        font-weight: normal;
        margin-bottom: 10px;
    }

    h4 {
        font-size: 18px;
        letter-spacing: 0.25px;
        font-weight: normal;
        margin-bottom: 25px;
    }

    #default .vehicle-grid {
        margin-bottom: 20px;
    }

    #mobile .vehicle-grid {
        margin-bottom: 15px;
    }

    .inline {
        display: inline;
    }

    .info-icon {
        display: inline-block;
        margin-left: 5px;
        cursor: pointer;
    }

    /** default styles */
    #default {
        padding-left: 0px;
        padding-right: 0px;
        /*max-width: 1305px;*/
        /*max-width: 1020px;*/
        position: center;
        margin: auto;
        background-color: white;
        /*margin-top: -60px;*/
    }

    #default.bmw {
        max-width: 1020px;
    }

    #default .inner {
        padding-left: 15px;
        padding-right: 15px;
    }

    /* mobile only */
    #mobile.bmw {
        padding-left: 20px;
        padding-right: 20px;
        background-color: white;
        margin-top: -60px;
    }

    #mobile.bmwBike {
        margin: -60px auto 0px;
    }

    #default button {
        font-size: 14px;
        font-weight: normal;
        text-transform: none;
        padding: 0px 50px;
        height: 50px;
        width: 270px;
    }

    #mobile button {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: normal;
        text-transform: none;
        padding: 0px 50px;
        height: 50px;
        width: 270px;
    }

    #default .v-dialog {
        width: unset;
        margin: 55px;
    }

    #mobile .v-dialog {
        width: unset;
        margin: 5px auto;
    }

    .v-menu__content {
        margin-top: 40px;
        border-radius: 0px;
    }

    #mobile .v-menu__content {
        margin-top: 50px;
    }

    .v-select-list {
        border-radius: 0px !important;
    }

    .v-menu__content .v-list {
        padding: 0px !important;
        border: 0.5px solid darkgrey;
    }

    .v-list-item:hover, .v-list-item--active {
        background-color: #0062ff;
    }

    .v-list-item--active .v-list-item__content {
        color: white;
    }

    .v-list-item__content:hover {
        color: white;
    }

    .v-input--selection-controls__input i {
        height: 30px !important;
        width: 30px !important;
    }


</style>
import axios from 'axios'

export default {
    async fetchSettings (settingsUrl) {

        return await axios
            .get(settingsUrl)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchValidity (validityUrl) {

             return await axios
                 .get(validityUrl)
                 .then(response => response.data)
                 .catch(e => {
                     console.log(e)
                 })
         },
    async fetchLabels (labelsUrl) {

        return await axios
            .get(labelsUrl)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchOffers (filterUrl) {

        return await axios
            .get(filterUrl)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
    async fetchBucketStats (bucketUrl) {

        return await axios
            .get(bucketUrl)
            .then(response => response.data)
            .catch(e => {
                console.log(e)
            })
    },
}
